import React from 'react';
import styled from 'styled-components';

interface ImageViewerProps {
  isOpen: boolean;
  onRequestClose: () => void;
  image: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ isOpen, onRequestClose, image }) => {
  const [rotation, setRotation] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = image;
    link.download = 'image.jpg';
    link.click();
  };

  const rotateImage = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const zoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };

  return isOpen ? (
    <Overlay onClick={onRequestClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <Button onClick={onRequestClose}>Close</Button>
        {image && (
          <ImageWrapper rotation={rotation} zoom={zoom}>
            <img src={image} alt="Selected" />
          </ImageWrapper>
        )}
        <Button onClick={downloadImage}>Download</Button>
        <Button onClick={rotateImage}>Rotate</Button>
        <Button onClick={zoomIn}>Zoom In</Button>
        <Button onClick={zoomOut}>Zoom Out</Button>
      </Modal>
    </Overlay>
  ) : null;
};

export default ImageViewer;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Modal = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
`;

const Button = styled.button`
  margin: 5px;
`;

const ImageWrapper = styled.div<{ rotation: number; zoom: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    transform: ${({ rotation, zoom }) => `rotate(${rotation}deg) scale(${zoom})`};
    transition: transform 0.3s;
    max-width: 100%;
    max-height: 80vh;
  }
`;
