import axios from "axios";
import { AppConfig } from "config";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import RoleEditor from "./RoleEditor";

const initialRoles = [
  {
    id: "admin",
    name: "Admin",
    allowedPages: {
      props: {
        users: true,
        usersVerification: true,
        apartments: true,
        shortTermRequest: true,
        longTermRequest: true,
        apartmentComplaints: true,
        landlordComplaints: true,
        contractCancelationRequest: true,
        contractRequest: true,
        chats: true,
        facilitiesCategories: true,
        facilitiesSubcategories: true,
        shorts: true,
      },
    },
  },
  {
    id: "manager",
    name: "Manager",
    allowedPages: {
      props: {
        users: true,
        usersVerification: false,
        apartments: true,
        shortTermRequest: true,
        longTermRequest: true,
        apartmentComplaints: true,
        landlordComplaints: true,
        contractCancelationRequest: true,
        contractRequest: true,
        chats: true,
        facilitiesCategories: false,
        facilitiesSubcategories: false,
        shorts: false,
      },
    },
  },
];

function RoleManagement() {
  const [roles, setRoles] = useState([]);
  const [list, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    const savedRoles = JSON.parse(localStorage.getItem("roles"));
    if (savedRoles) {
      setRoles(savedRoles);
    } else {
      setRoles(initialRoles);
      localStorage.setItem("roles", JSON.stringify(initialRoles));
    }

    fetchUsers();
  }, []);

  const handleSaveRoles = (updatedRoles) => {
    localStorage.setItem("roles", JSON.stringify(updatedRoles));
    setRoles(updatedRoles);
  };

  const fetchUsers = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(`${AppConfig.API_URL}/list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      
      setUsers(response.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
  };

  const assignRoleToUser = async () => {
    if (!selectedUser || !selectedRole) {
      alert("Please select a user and a role.");
      return;
    }

    const token = localStorage.getItem("accessToken");
    if (!token) {
      alert("Token is missing. Please log in.");
      window.location.href = "/login";
      return;
    }

    const role = roles.find((role) => role.id === selectedRole);
    if (!role) {
      alert("Role not found.");
      return;
    }

    try {
      await axios.post(
        `${AppConfig.API_URL}/edit-user`,
        {
          id: selectedUser,
          roleId: selectedRole,
          allowedPages: role.allowedPages.props,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      alert("Role successfully assigned to the user.");
    } catch (error) {
      console.error("Error assigning role:", error);
      alert("Failed to assign role.");
    }
  };

  return (
    <Wrapper>
      <Title>Role Management</Title>
      <RoleEditorWrapper>
        <RoleEditor roles={roles} onSave={handleSaveRoles} />
      </RoleEditorWrapper>
      <AssignRoleSection>
        <SectionTitle>Assign a Role to a User</SectionTitle>

                <FormGroup>
          <Label>Select User:</Label>
          <Select onChange={(e) => setSelectedUser(e.target.value)}>
            <option value="">-- Select User --</option>
            {list?.map((list) => (
              <option key={list.id} value={list.id}>
                {list.firstName} {list.lastName} ({list.login})
              </option>
            ))}
          </Select>
        </FormGroup>



        <FormGroup>
          <Label>Select Role:</Label>
          <Select onChange={(e) => setSelectedRole(e.target.value)} value={selectedRole}>
            <option value="">-- Select Role --</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </Select>
        </FormGroup>

        <Button onClick={assignRoleToUser}>Assign Role</Button>
      </AssignRoleSection>
    </Wrapper>
  );
}

export default RoleManagement;

// Styled Components
const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const RoleEditorWrapper = styled.div`
  margin-bottom: 30px;
`;

const AssignRoleSection = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
