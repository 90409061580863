import { AppConfig } from 'config';
import { regex } from 'const';
import crudProvider from 'ra-data-nestjsx-crud';
import { fetchUtils } from 'react-admin';
import { specialFields } from 'types/specialFields';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('accessToken');
  const replaceUrl = applyCustomReplacements(url);

  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(replaceUrl, options);
};

const applyCustomReplacements = (url: string): string => {
  let cleanUrl = url.replaceAll(regex.arrayIndex, '').replaceAll(regex.filterCondition, '$contL');
  const urlParams = new URLSearchParams(cleanUrl);
  const filters = urlParams.getAll('filter');
  const modifiedFilters = modifyFilters(filters);

  urlParams.delete('filter');
  modifiedFilters.forEach((filter) => urlParams.append('filter', filter));
  return cleanUrl.split('?')[0] + '?' + urlParams.toString();
};

const modifyFilters = (filters: string[]): string[] => {
  return filters.map((filter) => {
    const field = specialFields.find((field) => filter.startsWith(field));
    if (field) {
      return filter.replace('$contL', '$eq');
    }
    return filter;
  });
};

export default crudProvider(AppConfig.API_URL, httpClient);
