import { ListProps } from '@material-ui/core';
import { StyledImageField } from 'components';
import { FC } from 'react';
import { Datagrid, DateField, List, ReferenceField, SelectInput, TextField, TextInput } from 'react-admin';
import styled from 'styled-components';

const statusChoices = [
  { id: '{ACTIVE}', name: 'ACTIVE' },
  { id: '{DRAFT}', name: 'DRAFT' },
  { id: '{PAUSED}', name: 'PAUSED' },
  { id: '{PROCESSING}', name: 'PROCESSING' },
  { id: '{PUBLISHED}', name: 'PUBLISHED' },
];

const CustomersFilter = [
  <TextInput key="id" label="Поиск по id" source="id" defaultValue="" alwaysOn />,
  <TextInput key="city" label="Поиск по городу" source="city" alwaysOn />,
  <SelectInput
    key="shortTermRent.status"
    label="Статус"
    source="shortTermRent.status"
    choices={statusChoices}
    alwaysOn
  />,
];

const ApartmentAdList: FC<ListProps> = (props) => {
  return (
    <List filters={CustomersFilter} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <StyledImageField label="Изображение" source="media.photos.[0].fileKey" sortable={false} />
        <ReferenceField label="Название" source="id" reference="apartment-ad" link="show">
        <TextField source="description.name" />
        </ReferenceField>
        <StyledTextField label="Статус" source="shortTermRent.status[0]" sortable={false} />
        <StyledTextField label="Описание" source="description.description" sortable={false} />
        <TextField label="Период аренды" source="rentPeriodType" />
        <TextField label="Тип жилья" source="apartmentType" />
        <TextField label="Страна" source="country" />
        <TextField label="Город" source="city" />
        <DateField locales="rus" showTime label="Создан" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default ApartmentAdList;

const StyledTextField = styled(TextField)`
  display: block;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
