import React, { useState } from 'react';
import { ImageField, ImageFieldProps } from 'react-admin';
import styled, { css } from 'styled-components';

import ImageViewer from './ImageViewer'; 

type StyledImageFieldProps = ImageFieldProps & { $size?: number; $margin?: number };

const StyledImageField: React.FC<StyledImageFieldProps> = ({ $size = 50, $margin = 0, ...props }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openModal = (image: string) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation(); // Prevent the row click event
          const img = e.currentTarget.querySelector('img');
          if (img) openModal(img.src);
        }}
      >
        <StyledImage {...props} $size={$size} $margin={$margin} />
      </div>
      {selectedImage && (
        <ImageViewer
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          image={selectedImage}
        />
      )}
    </>
  );
};

const StyledImage = styled(ImageField)<StyledImageFieldProps>`
  ${({ $size = 50, $margin = 0 }) => css`
    max-width: ${$size}px;
    width: fit-content !important;
    height: ${$size}px;
    margin-right: ${$margin}px !important;
    margin-top: ${$margin}px !important;
    cursor: pointer;

    img {
      max-width: ${$size}px !important;
      width: fit-content !important;
      height: ${$size}px !important;
      margin: 0 !important;
    }
  `}
`;

export default StyledImageField;
