import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { Datagrid, DateField, FunctionField, List, ReferenceField, SelectInput, TextField, TextInput } from 'react-admin';

const ContractsList: FC<ListProps> = (props) => {
  const statusChoices = [
    { id: 'REJECTED', name: 'REJECTED' },
    { id: 'CREATED', name: 'CREATED' },
    { id: 'OFFERING', name: 'OFFERING' },
    { id: 'CONCLUDED', name: 'CONCLUDED' },
    { id: 'COMPLETED', name: 'COMPLETED' },
    { id: 'FREEZE', name: 'FREEZE' },
  ];

  const CustomersFilter = [
    <TextInput key="landlord.firstName" label="Поиск по имени" source="landlord.firstName" alwaysOn />,
    <SelectInput key="status" label="Статус" source="status" choices={statusChoices} alwaysOn />,
  ];

  return (
    <List filters={CustomersFilter} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <ReferenceField label="Название" source="id" reference="contracts" link="show">
        <TextField source="baseApartmentAdData.title" />
        </ReferenceField>
        <TextField label="Стоимость" source="cost" />
        <FunctionField label="Запрос на отказ" render={(record: any) => (record.rejectReason ? 'Да' : 'Нет')} />
        <DateField showTime label="Дата создания" source="createdAt" />
        <DateField showTime label="Дата прибытия" source="arrivalDate" />
        <DateField showTime label="Дата отъезда" source="departureDate" />
        <TextField label="Город" sortable={false} source="baseApartmentAdData.address.city" />
        <TextField label="Улица" sortable={false} source="baseApartmentAdData.address.street" />
        <TextField label="Номер дома" sortable={false} source="baseApartmentAdData.address.houseNumber" />
        <TextField label="Статус" source="status" />
      </Datagrid>
    </List>
  );
};

export default ContractsList;
