export enum ResourceName {
  USERS = 'users',
  USER_IDENTITY_REQUESTS = 'user-identity-requests',
  APARTMENT_AD = 'apartment-ad',
  APARTMENT_AD_REQUESTS_SHORT_RENT = 'short-term-rent-requests',
  APARTMENT_AD_REQUESTS_LONG_RENT = 'long-term-rent-requests',
  SHORT_TERM_RENTS = 'short-term-rents',
  LONG_TERM_RENTS = 'long-term-rents',
  APARTMENT_AD_COMPLAINT = 'apartment-ad-complaint',
  USER_COMPLAINT = 'user-complaint',
  CHATS = 'chats',
  MESSAGES = 'messages',
  CONTRACTS = 'contracts',
  CONTRACTS_REJECT = 'contract-reject-requests',
  CONTRACTS_COPY = 'contract-requests',
  CHAT_MEMBERS = 'chat-members',
  APARTMENT_FACILITIES = 'apartment-facilities-categories',
  APARTMENT_SUB_FACILITIES = 'apartment-facilities-subcategories',
  SHORTS = 'shorts',
  SHORTS_HEADING = 'shorts-heading',
  INCOME_OVERVIEW_LIST = 'contracts/info',
  ROLE_MANAGMENT = 'create-user',
  PAYMENT_LOG = 'payout-transaction',
}
