import { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Aws, http } from 'services';
import { ResourceName } from 'types';

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
const ApartmentFacilitiesSubCreate = () => {
  const redirect = useRedirect();
  const [choices, setChoices] = useState([]);

  const handleSubmit = async (data: FieldValues) => {
    const signedUrl = await Aws.getSignedUrl({ fileName: data.icons.title, fileCategory: 'ICON' });
    await Aws.loadToBucket(signedUrl, data.icons.rawFile);

    await http.post(ResourceName.APARTMENT_SUB_FACILITIES, {
      ...data,
      url: signedUrl,
    });
    redirect('/' + ResourceName.APARTMENT_SUB_FACILITIES);
  };

  useEffect(() => {
    http.get(ResourceName.APARTMENT_FACILITIES).then((response) => setChoices(response.data.data));
  }, []);

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <AutocompleteInput source="categoryId" optionText="title" choices={choices} sx={{ width: '600px' }} />
        <TextInput label="Название" source="title" defaultValue="" multiline sx={{ width: '600px' }} />
        <TextInput label="Описание" source="description" defaultValue="" multiline sx={{ width: '600px' }} />
        <BooleanInput label="Популярный" source="IsPopular" />
        <ImageInput source="icons" label="Иконка" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default ApartmentFacilitiesSubCreate;
