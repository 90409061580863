import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

function RoleEditor({ roles, onSave }) {
  const [selectedRole, setSelectedRole] = useState(null);
  const [newRoleName, setNewRoleName] = useState('');
  const [newRolePermissions, setNewRolePermissions] = useState({
    users: false,
    usersVerification: false,
    apartments: false,
    shortTermRequest: false,
    longTermRequest: false,
    apartmentComplaints: false,
    landlordComplaints: false,
    contractCancelationRequest: false,
    contractRequest: false,
    chats: false,
    facilitiesCategories: false,
    facilitiesSubcategories: false,
    shorts: false,
  });

  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };

  const handlePermissionChange = (page) => {
    if (selectedRole) {
      setSelectedRole({
        ...selectedRole,
        allowedPages: {
          props: {
            ...selectedRole.allowedPages.props,
            [page]: !selectedRole.allowedPages.props[page],
          },
        },
      });
    } else {
      setNewRolePermissions({
        ...newRolePermissions,
        [page]: !newRolePermissions[page],
      });
    }
  };

  const handleSavePermissions = () => {
    const updatedRoles = roles.map((role) =>
      role.id === selectedRole.id ? selectedRole : role
    );
    onSave(updatedRoles);
    setSelectedRole(null);
  };

  const handleAddNewRole = () => {
    const newRole = {
      id: newRoleName.toLowerCase(),
      name: newRoleName,
      allowedPages: { props: newRolePermissions },
    };
    onSave([...roles, newRole]);
    setNewRoleName('');
    setNewRolePermissions({
      users: false,
      usersVerification: false,
      apartments: false,
      shortTermRequest: false,
      longTermRequest: false,
      apartmentComplaints: false,
      landlordComplaints: false,
      contractCancelationRequest: false,
      contractRequest: false,
      chats: false,
      facilitiesCategories: false,
      facilitiesSubcategories: false,
      shorts: false,
    });
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Grid container spacing={3}>
        {/* Список ролей */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Список ролей
            </Typography>
            <List>
              {roles.map((role) => (
                <ListItem
                  key={role.id}
                  button
                  onClick={() => handleSelectRole(role)}
                >
                  <ListItemText primary={role.name} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Редактирование роли */}
        {selectedRole && (
    <Grid item xs={12}>
    <Paper
      elevation={3}
      style={{
        padding: '20px',
        margin: '20px 0',
        minWidth: '600px',
        maxWidth: '100%',
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: '20px',
        }}
      >
        Редактирование роли: {selectedRole.name}
      </Typography>
      <Grid container spacing={3}>
        {Object.keys(selectedRole.allowedPages.props).map((page) => (
          <Grid item xs={12} sm={6} md={4} key={page}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedRole.allowedPages.props[page]}
                  onChange={() => handlePermissionChange(page)}
                />
              }
              label={page}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: '14px',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSavePermissions}
        style={{
          marginTop: '20px',
          fontWeight: 'bold',
        }}
      >
        Сохранить изменения
      </Button>
    </Paper>
  </Grid>
  
      
        )}

        {/* Создание новой роли */}
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Создание новой роли
            </Typography>
            <TextField
              label="Название роли"
              fullWidth
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
            <Grid container spacing={2}>
              {Object.keys(newRolePermissions).map((page) => (
                <Grid item xs={6} md={4} key={page}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newRolePermissions[page]}
                        onChange={() => handlePermissionChange(page)}
                      />
                    }
                    label={page}
                  />
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddNewRole}
              style={{ marginTop: '20px' }}
            >
              Добавить роль
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RoleEditor;
