import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Line,LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface ChartComponentProps {
  data: Array<{ month: string; value: number }>;
}

const ChartComponent: React.FC<ChartComponentProps> = ({ data }) => {
  const [chartType, setChartType] = React.useState("bar");

  const handleChartTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartType(event.target.value);
  };

  return (
    <Box>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="chartType"
          name="chartType"
          value={chartType}
          onChange={handleChartTypeChange}
        >
          <FormControlLabel value="bar" control={<Radio />} label="Столбчатая диаграмма" />
          <FormControlLabel value="line" control={<Radio />} label="Линейный график" />
        </RadioGroup>
      </FormControl>

      <ResponsiveContainer width="100%" height={400}>
        {chartType === "bar" ? (
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        ) : (
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#8884d8" />
          </LineChart>
        )}
      </ResponsiveContainer>
    </Box>
  );
};

export default ChartComponent;
