import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

const PaymentsLog: FC<ListProps> = (props) => {
  return (
    <List sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="ID" source="id" sortable={false} />
        <ReferenceField label="Собственник" source="landlordId" reference="users" link="show" sortable={false}>
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField label="Контракт" source="contractId" reference="contracts" link="show" sortable={false}>
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField label="Объявления" source="apartmentId" reference="apartment-ad" link="show" sortable={false}>
          <TextField source="description.name" />
        </ReferenceField>
        <TextField label="Card ID" source="cardId" sortable={false} />
        <TextField label="Выплата ID" source="paymentId" sortable={false} />
        <TextField label="Сумма" source="amount" sortable={false} />
        <TextField label="Статус" source="status" />
        <TextField label="Описание" source="description" />
        <DateField locales="rus" showTime label="Дата прибытия" source="arrivalDate" />
        <DateField locales="rus" showTime label="Создан" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default PaymentsLog;
