import { StorageService } from 'services';

import { httpClient } from './aws';

const fetchApartUrl = async (id: string) => {
  const url = `admin-panel/apartment-ad/${id}/slug`;
  const token = StorageService.getAccessToken();
  httpClient.setAuthorizationHeader(token || '');
  const res = await httpClient.get(url);
  return res.data[0].slug; 
};

export const getApartSlug = async (id: string) => {
  try {
    const slug = await fetchApartUrl(id);
    return slug;
  } catch (error) {
    console.error('Error fetching apartment slug:', error);
    throw error;
  }
};
