import React, { FC, useEffect, useState } from 'react';
import { getApartSlug } from 'services/redirectToSlug';

type RedirectToSiteProps = {
  id: string;
};

const RedirectToSite: FC<RedirectToSiteProps> = ({ id }) => {
  const [slug, setSlug] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSlug = async () => {
      try {
        const result = await getApartSlug(id);
        setSlug(result);
      } catch (error) {
        setError('Failed to fetch slug');
        console.error('Error fetching slug:', error);
      }
    };

    fetchSlug();
  }, [id]);

  const mainUrl = process.env.REACT_APP_API_URL;
  const baseUrl = mainUrl === 'https://api.livin.kz' ? 'https://livin.kz' : 'https://staging.livin.kz';
  const apartmentUrl = `${baseUrl}/apartment/${slug}`;

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!slug) {
    return <div>Loading...</div>;
  }

  return (
    <div style={style}>
      <a href={apartmentUrl} target="_blank" rel="noopener noreferrer" style={linkStyle}>
        {apartmentUrl}
      </a>
    </div>
  );
};

export default RedirectToSite;

const style: React.CSSProperties = {};

const linkStyle: React.CSSProperties = {
  color: '#007BFF',
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: 'bold',
};
