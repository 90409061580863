import axios from 'axios';
import { AppConfig } from 'config';

import { Http } from './http';
import StorageService from './storage-service';

type getSignedUrlProps = {
  fileName: string;
  fileCategory: string;
  advertId?: string;
  chatId?: string;
};

export const httpClient = new Http(
  axios.create({
    baseURL: AppConfig.BASE_API_URL!,
  }),
);

class Aws {
  getSignedUrl = async ({ fileName, fileCategory, advertId = '', chatId = '' }: getSignedUrlProps) => {
    const apartmentAdId = advertId !== '' ? `&apartmentAdId=${advertId}` : '';
    const chatIdQuery = chatId !== '' ? `&chatId=${chatId}` : '';
    const url = `v1/aws/signed-url?fileName=${fileName}&fileCategory=${fileCategory}${apartmentAdId}${chatIdQuery}`;
    const token = StorageService.getAccessToken();
    httpClient.setAuthorizationHeader(token || '');
    const res = await httpClient.get(url);

    return res.data;
  };

  loadToBucket = async (url: string, file: File) => {
    return await axios.put(`${url}`, file);
  };

  deleteFromBucket = async (fileKey: string, fileCategory: string) => {
    try {
      return await httpClient.delete(`aws/delete-s3-file?fileKey=${fileKey}&fileCategory=${fileCategory}`);
    } catch (e) {
      console.log(e, 'error from delete attachment');
    }
  };
}

export default new Aws();
