import { ListProps } from '@material-ui/core';
import { StyledImageField } from 'components';
import { FC } from 'react';
import { Datagrid, DateField, EmailField, List, ReferenceField, SelectInput, TextField, TextInput } from 'react-admin';

const statusChoices = [
  { id: 'APPROVED', name: 'APPROVED' },
  { id: 'NOT_CONFIRMED', name: 'NOT_CONFIRMED' },
  { id: 'REJECTED', name: 'REJECTED' },
  { id: 'PROCESSING', name: 'PROCESSING' },
];

const isLandlordChoices = [
  { id: 'true', name: 'true' },
  { id: 'false', name: 'false' },
];

const CustomersFilter = [
  <TextInput key="id" label="Поиск по ID" source="id||$eq" alwaysOn />,
  <TextInput key="firstName" label="Поиск по имени" source="firstName" alwaysOn />,
  <TextInput key="lastName" label="Поиск по фамилии" source="lastName" alwaysOn />,
  <TextInput key="email" label="Поиск по почте" source="email" alwaysOn />,
  <TextInput key="phone" label="Поиск по номеру" source="phone" alwaysOn />,
  <SelectInput key="identityStatus" label="Статус" source="identityStatus" choices={statusChoices} alwaysOn />,
  <SelectInput key="isLandlord" label="Собственник?" source="isLandlord" choices={isLandlordChoices} alwaysOn />,
];

const UsersList: FC<ListProps> = (props) => {
  return (
    <List filters={CustomersFilter} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <StyledImageField label="Аватар" source="avatarKey" sortable={false} />
        <ReferenceField label="ID" source="id" reference="users" link="show" sortable={false}>
          <TextField source="id" />
        </ReferenceField>
        <TextField label="Телефон" source="phone" sortable={false} />
        <EmailField label="Почта" source="email" sortable={false} />
        <TextField label="Имя" source="firstName" />
        <TextField label="Фамилия" source="lastName" />
        <TextField label="Дата рождения" source="birthDate" />
        <TextField label="Пол" source="gender" />
        <TextField label="Статус" source="identityStatus" />
        <DateField locales="rus" showTime label="Создан" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default UsersList;
