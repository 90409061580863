import { http } from '../services';

type cancelRentAsyncType = {
  id: string;
  validExcuse?: boolean;
  requestingUserRole: 'TENANT' | 'LANDLORD';
  force?: boolean;
  isContractRequest?: boolean
};

export const cancelRentAsync = async ({ id, requestingUserRole, validExcuse, force = false, isContractRequest }: cancelRentAsyncType) => {
  try {
    const url = isContractRequest ? `/contract-requests/${id}/cancel` : `/contracts/${id}/cancel`
    const res = await http.post(url, {
      cancellationMeta: {
        force,
        validExcuse,
      },
      requestingUserRole,
    });

    return res;
  } catch (e) {
    console.log(e, 'error');
  }
};
