import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

Font.register({
  family: 'EuclidCircularB',
  src: '/fonts/EuclidCircularB-Medium.ttf',
  fontStyle: 'normal',
  fontWeight: 'medium',
});
Font.register({
  family: 'EuclidCircularB',
  src: '/fonts/EuclidCircularB-Regular.ttf',
  fontStyle: 'normal',
  fontWeight: 'normal',
});

enum ApartmentType {
  Aparthotel = 'APARTHOTEL',
  Cottage = 'COTTAGE',
  Flat = 'FLAT',
  Guesthouse = 'GUESTHOUSE',
  Hostel = 'HOSTEL',
  MiniHotel = 'MINI_HOTEL',
  Room = 'ROOM',
}

enum ShortTermRentCancellationPolicyType {
  Flexible = 'FLEXIBLE',
  Inflexible = 'INFLEXIBLE',
  Moderate = 'MODERATE',
  Strict = 'STRICT',
}

const rentCancellationPolicyMapping = {
  [ShortTermRentCancellationPolicyType.Flexible]: {
    label: 'Гибкие',
    text: 'Гостям гарантирован полный возврат при отмене не позднее чем за 24 часа до прибытия. Арендодатель не получит выплату. Если бронирование отменяется позже или во время проживания, мы переводим арендодателю полную сумму. Кроме случаев возврата в течение 24 часов из-за уважительной причины о жилье.',
  },
  [ShortTermRentCancellationPolicyType.Moderate]: {
    label: 'Умеренные',
    text: 'Гостям гарантирован полный возврат при отмене не позднее чем за 5 дней до прибытия. Арендодатель не получит выплату. Если бронирование отменяется позже, 50% стоимости оставшихся ночей. Если бронирование отменяется во время проживания, мы переводим арендодателю полную сумму',
  },
  [ShortTermRentCancellationPolicyType.Inflexible]: {
    label: 'Негибкие',
    text: 'Гости получают полный возврат при отмене бронирования не позднее чем за 30 дней до прибытия. Гости получают полный возврат при отмене в течение 48 часов с момента бронирования, если до прибытия осталось не меньше 14 дней. При отмене за 7-30 дней до прибытия арендодателю выплатят 50% стоимости проживания. При отмене менее чем за 7 дней до прибытия арендодателю выплатят 100% стоимости бронирования. Если бронирование отменяется во время проживания, мы переводим арендодателю полную сумму',
  },
  [ShortTermRentCancellationPolicyType.Strict]: {
    label: 'Строгие',
    text: 'Гости получают полный возврат при отмене в течение 48 часов с момента бронирования, если до прибытия осталось не меньше 14 дней. Гости получат возврат 50% если уже прошло 48 часов с момента бронирования, а до момента заезда больше 7 дней. При более поздней отмене арендодатель получит 100% оплаты бронирования. Если бронирование отменяется во время проживания, мы переводим арендодателю полную сумму.',
  },
};

const PdfDocument = ({ contractId, contract, isTenant, type, transaction }: any) => {
  const typeHousingMapping = {
    [ApartmentType.Flat]: 'Квартира',
    [ApartmentType.Room]: 'Комната',
    [ApartmentType.Cottage]: 'Дом, коттедж',
    [ApartmentType.Hostel]: 'Хостел',
    [ApartmentType.MiniHotel]: 'Мини гостиница',
    [ApartmentType.Guesthouse]: 'Гостевой дом',
    [ApartmentType.Aparthotel]: 'Апарт-отель',
  };

  const startDate = dayjs(contract?.arrivalDate).startOf('day');
  const endDate = dayjs(contract?.departureDate).startOf('day');

  const differenceInDays = endDate.diff(startDate, 'day') === 0 ? 1 : endDate.diff(startDate, 'day');

  const Header = () => (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <View style={{ flexDirection: 'column' }}>
        <Text style={{ fontFamily: 'EuclidCircularB', fontWeight: 'medium', fontSize: 18 }}>
          Ваша квитанция от Livin
        </Text>
        <Text style={{ fontFamily: 'EuclidCircularB', fontSize: 12 }}>
          Квитанция №{transaction.payment_id} • {dayjs(contract?.createdAt).format('D MMMM YYYY')}
        </Text>
      </View>
      <View>
        <Image src="/img/logo.png" style={{ width: '80px', height: '22px' }} />
      </View>
    </View>
  );

  const Contract = () => (
    <View
      style={{
        padding: '10px 16px',
        borderRadius: '6px',
        border: '2px solid #F8F8F8',
      }}>
      <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '16px' }}>
        {contract.baseApartmentAdData.address.city}, Казахстан
      </Text>
      <Text style={{ marginTop: '5px', fontFamily: 'EuclidCircularB', fontSize: '10px' }}>
        Количество ночей: {differenceInDays}
      </Text>
      <View style={{ margin: '10px 0', width: '100%', height: '2px', backgroundColor: '#F8F8F8' }} />
      <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '8px', textTransform: 'capitalize' }}>
        {dayjs(contract?.arrivalDate).format('dd. D MMM YYYY')} -{' '}
        {dayjs(contract?.departureDate).format('dd. D MMM YYYY')}
      </Text>
      <Text style={{ marginTop: '3px', fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
        {typeHousingMapping[type as ApartmentType]} • {contract.guests.numberOfAdult + contract.guests.numberOfChildren}{' '}
        гостей
      </Text>
      <Text style={{ marginTop: '3px', fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
        <Text style={{ fontWeight: 'medium' }}>ID контракта: &nbsp;</Text>
        <Text>{contractId}</Text>
      </Text>
      <Text style={{ marginTop: '3px', fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
        <Text style={{ fontWeight: 'medium' }}>Арендатор: &nbsp;</Text>
        <Text>
          {contract.tenant.firstName} {contract.tenant.lastName}
        </Text>
      </Text>
      <View style={{ margin: '10px 0', width: '100%', height: '2px', backgroundColor: '#F8F8F8' }} />
      <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
        <Text style={{ fontWeight: 'medium' }}>Правила отмены: &nbsp;</Text>
        <Text>
          {
            rentCancellationPolicyMapping[contract.shortTermCancellationPolicy as ShortTermRentCancellationPolicyType]
              .label
          }
        </Text>
      </Text>
      <Text style={{ marginTop: '3px', fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
        {
          rentCancellationPolicyMapping[contract.shortTermCancellationPolicy as ShortTermRentCancellationPolicyType]
            .text
        }
      </Text>
    </View>
  );

  const ApartmentInfo = () => (
    <View
      style={{
        padding: '10px 16px',
        borderRadius: '6px',
        border: '2px solid #F8F8F8',
      }}>
      <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '10px', fontWeight: 'medium' }}>
        {contract.baseApartmentAdData.title}
      </Text>
      <Text style={{ marginTop: '5px', fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
        <Text style={{ fontWeight: 'medium' }}>Собственник: &nbsp;</Text>
        <Text>
          {contract.landlord.firstName} {contract.landlord.lastName}
        </Text>
      </Text>
      <Text style={{ marginTop: '5px', fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
        <Text style={{ fontWeight: 'medium' }}>Адрес: &nbsp;</Text>
        <Text>
          {contract.baseApartmentAdData.address.street} {contract.baseApartmentAdData.address.houseNumber}
        </Text>
      </Text>
    </View>
  );

  const PriceInfo = () => (
    <View
      style={{
        marginTop: '5px',
        padding: '10px 16px',
        borderRadius: '6px',
        border: '2px solid #F8F8F8',
      }}>
      <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '16px' }}>Информация о цене</Text>
      <View
        style={{
          marginTop: '8px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottom: '1px solid #F8F8F8',
        }}>
        <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
          Цена: {contract.cost} ₸ * {differenceInDays} ночей
        </Text>
        <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '8px' }}>{contract.cost * differenceInDays} ₸</Text>
      </View>
      <View
        style={{
          marginTop: '8px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottom: '1px solid #F8F8F8',
        }}>
        <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '8px' }}>Комиссия Livin – {isTenant ? 12 : 3}%</Text>
        <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '8px' }}>
          {isTenant ? contract.cost * differenceInDays * 0.12 : contract.cost * differenceInDays * 0.03}₸
        </Text>
      </View>
      <View style={{ marginTop: '8px', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Text style={{ fontFamily: 'EuclidCircularB', fontWeight: 'medium', fontSize: '8px' }}>
          Итого:{' '}
          {Math.round(isTenant ? contract.cost * differenceInDays * 1.12 : contract.cost * differenceInDays * 1.03)} ₸
        </Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page
        size="A4"
        style={{
          padding: '42px',
          flexDirection: 'column',
        }}>
        <Header />
        <View
          style={{
            marginTop: '25px',
            width: '100%',
            flexDirection: 'row',
          }}>
          <View
            style={{
              marginRight: '5px',
              flex: 2,
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <Contract />
            <View style={{ padding: '10px', flexDirection: 'column' }}>
              <Text style={{ fontFamily: 'EuclidCircularB', fontWeight: 'medium', fontSize: '10px' }}>
                Есть вопросы?
              </Text>
              <Text style={{ fontFamily: 'EuclidCircularB', fontWeight: 'medium', fontSize: '10px' }}>
                Пишите на support@livin.kz
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 3,
              flexDirection: 'column',
            }}>
            <ApartmentInfo />
            <PriceInfo />
          </View>
        </View>
        <View
          style={{
            marginTop: '10px',
            padding: '10px',
            borderRadius: '6px',
            backgroundColor: '#F8F8F8',
          }}>
          <Text style={{ fontFamily: 'EuclidCircularB', fontWeight: 'medium', fontSize: '10px' }}>
            Условия предоставления услуг платёжной системы
          </Text>
          <Text style={{ marginTop: '5px', fontFamily: 'EuclidCircularB', fontSize: '10px' }}>
            Проведение платежа осуществляется Банком-Партнером и/или Платежной организацией-Партнером. Арендатор
            самостоятельно передает необходимые данные для оплаты Банку -Партнеру и/или Платежной организации - Партнеру
            без привлечения Компании. Компания не имеет доступа к таким данным и не является участником правоотношений
            между Арендатором и Банком или платежной организацией по проведению арендной платы и иных платежей.
          </Text>
        </View>
        <View
          style={{
            marginTop: '10px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '10px' }}>ТОО «Birdhouse»</Text>
            <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '10px' }}>Ул. Байтурсынова 141,</Text>
            <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '10px' }}>050013</Text>
            <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '10px' }}>Алматы, Казахстан</Text>
            <Text style={{ fontFamily: 'EuclidCircularB', fontSize: '10px' }}>www.livin.kz</Text>
          </View>
          <View>
            <Image src="/img/logo_invert.png" style={{ width: '98px', height: '28px' }} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
