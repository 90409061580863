import { Create, NumberInput, SaveButton, SimpleForm, TextInput, Toolbar, useRedirect } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { http } from 'services';
import { ResourceName } from 'types';

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
const ShortsHeadingCreate = () => {
  const redirect = useRedirect();

  const handleSubmit = async (data: FieldValues) => {
    await http.post(ResourceName.SHORTS_HEADING, {
      tag: data.tag,
      order: data.order,
    });
    redirect('/' + ResourceName.SHORTS_HEADING);
  };
  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <TextInput label="Название" source="tag" defaultValue="" multiline sx={{ width: '600px' }} />
        <NumberInput label="Очередь" source="order" defaultValue="" multiline sx={{ width: '600px' }} />
      </SimpleForm>
    </Create>
  );
};

export default ShortsHeadingCreate;
