import { Box, Grid, MenuItem,TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AppConfig } from 'config';
import React, { useEffect, useState } from 'react';

import ChartComponent from '../ChartComponent/ChartComponent';

const IncomeOverviewShow: React.FC = () => {
  const [details, setDetails] = useState<IncomeDetails | null>();
  const [chartData] = useState([
    { month: 'Jan', value: 100000 },
    { month: 'Feb', value: 300000 },
    { month: 'Mar', value: 500000 },
    { month: 'Apr', value: 200000 },
  ]);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    rentType: 'Посуточная аренда',
    incomeType: 'Прием / выплаты',
    country: 'Казахстан',
    city: '',
  });

  const handleFilterChange = (key: string, value: string) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  useEffect(() => {
    const fetchContractInfo = async () => {
      const token = localStorage.getItem('accessToken');
      const queryParams = new URLSearchParams({
        ...(filters.startDate && { startDate: filters.startDate }),
        ...(filters.endDate && { endDate: filters.endDate }),
        ...(filters.rentType && { rentType: filters.rentType }),
        ...(filters.incomeType && { incomeType: filters.incomeType }),
        ...(filters.country && { country: filters.country }),
        ...(filters.city && { city: filters.city }),
      }).toString();

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${AppConfig.API_URL}/contracts/info?${queryParams}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.request(config);
        setDetails(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchContractInfo();
  }, [filters]);

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Обзор доходов
      </Typography>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Дата начала"
            type="date"
            value={filters.startDate}
            onChange={(e) => handleFilterChange('startDate', e.target.value)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Дата окончания"
            type="date"
            value={filters.endDate}
            onChange={(e) => handleFilterChange('endDate', e.target.value)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            select
            label="Тип аренды"
            value={filters.rentType}
            onChange={(e) => handleFilterChange('rentType', e.target.value)}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="Посуточная аренда">Посуточная аренда</MenuItem>
            <MenuItem value="Краткосрочная аренда">Месячная аренда</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            select
            label="Тип дохода"
            value={filters.incomeType}
            onChange={(e) => handleFilterChange('incomeType', e.target.value)}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="Прием / выплаты">Прием / выплаты</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            select
            label="Страна"
            value={filters.country}
            onChange={(e) => handleFilterChange('country', e.target.value)}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="Казахстан">Казахстан</MenuItem>
            <MenuItem value="Россия">Россия</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            select
            label="Город"
            value={filters.city}
            onChange={(e) => handleFilterChange('city', e.target.value)}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="Akozek">Akozek</MenuItem>
            <MenuItem value="Aktau">Aktau</MenuItem>
            <MenuItem value="Aktobe">Aktobe</MenuItem>
            <MenuItem value="Alga">Alga</MenuItem>
            <MenuItem value="Almaty">Almaty</MenuItem>
            <MenuItem value="Ardolayty">Ardolayty</MenuItem>
            <MenuItem value="Astana">Astana</MenuItem>
            <MenuItem value="Atyrau">Atyrau</MenuItem>
            <MenuItem value="Balkhash">Balkhash</MenuItem>
            <MenuItem value="Besqaynar">Besqaynar</MenuItem>
            <MenuItem value="Birlik">Birlik</MenuItem>
            <MenuItem value="Cholpon Ata">Cholpon Ata</MenuItem>
            <MenuItem value="Kabanbai Batyr">Kabanbai Batyr</MenuItem>
            <MenuItem value="Kamenniy Karjer">Kamenniy Karjer</MenuItem>
            <MenuItem value="Karaganda">Karaganda</MenuItem>
            <MenuItem value="Khromtau">Khromtau</MenuItem>
            <MenuItem value="Kokshetau">Kokshetau</MenuItem>
            <MenuItem value="Kostanay">Kostanay</MenuItem>
            <MenuItem value="Kyzylorda">Kyzylorda</MenuItem>
            <MenuItem value="Oskemen">Oskemen</MenuItem>
            <MenuItem value="Pavlodar">Pavlodar</MenuItem>
            <MenuItem value="Petropavlovsk">Petropavlovsk</MenuItem>
            <MenuItem value="Rudnyy">Rudnyy</MenuItem>
            <MenuItem value="Saty">Saty</MenuItem>
            <MenuItem value="Semey">Semey</MenuItem>
            <MenuItem value="Shymkent">Shymkent</MenuItem>
            <MenuItem value="Sotsialisticheskiy Kazakhstan">Sotsialisticheskiy Kazakhstan</MenuItem>
            <MenuItem value="Stepnogorsk">Stepnogorsk</MenuItem>
            <MenuItem value="Taldykorgan">Taldykorgan</MenuItem>
            <MenuItem value="Taraz">Taraz</MenuItem>
            <MenuItem value="Temirtau">Temirtau</MenuItem>
            <MenuItem value="Türkistan qalası">Türkistan qalасы</MenuItem>
            <MenuItem value="Uralsk">Uralsk</MenuItem>
            <MenuItem value="Алмалык">Алмалык</MenuItem>
            <MenuItem value="Бесагаш">Бесагаш</MenuItem>
            <MenuItem value="Панфилово">Панфилово</MenuItem>
            <MenuItem value="Ынтымақ">Ынтымақ</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Доход</Typography>
            <Typography variant="h6" fontWeight="bold">
              {(details?.totalIncomeFromLandlord && details.totalIncomeFromTenants) ? (details.totalIncomeFromLandlord + details.totalIncomeFromTenants - ((details.totalPayInCommission || 0) + (details.totalPayOutCommission || 0))).toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Оборот денежных средств</Typography>
            <Typography variant="h6" fontWeight="bold">
            {details?.totalTraffic ? details.totalTraffic.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Комиссия платежного партнера</Typography>
            <Typography variant="h6" fontWeight="bold">
              {(details?.totalPayInCommission ?? details?.totalPayOutCommission) ?( details.totalPayInCommission + details.totalPayOutCommission).toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Средняя стоимость аренды за сутки</Typography>
            <Typography variant="h6" fontWeight="bold">
              {details?.averageCostPerDay ? details.averageCostPerDay.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Средний срок аренды (дней)</Typography>
            <Typography variant="h6" fontWeight="bold">
              {details?.averageRentDays ? details.averageRentDays.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Количество бронирований</Typography>
            <Typography variant="h6" fontWeight="bold">{details?.totalContracts || 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Количество забронированных дней</Typography>
            <Typography variant="h6" fontWeight="bold">{details?.totalRentDays || 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Выплата</Typography>
            <Typography variant="h6" fontWeight="bold">
            {details?.totalPayOutAmount ? details.totalPayOutAmount.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Количество отмененных бронирований</Typography>
            <Typography variant="h6" fontWeight="bold">{details?.totalRejectedContracts}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Среднее время между бронированием и заездом (дней)</Typography>
            <Typography variant="h6" fontWeight="bold">
              {details?.averageTimeBetweenBookingAndArrival ? details.averageTimeBetweenBookingAndArrival.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Комиссионные сборы (гость)</Typography>
            <Typography variant="h6" fontWeight="bold">
            {details?.totalIncomeFromTenants ? details.totalIncomeFromTenants.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box border="1px solid #ddd" borderRadius="8px" p={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">Комиссионные сборы (собственник)</Typography>
            <Typography variant="h6" fontWeight="bold">
            {details?.totalIncomeFromLandlord ? details.totalIncomeFromLandlord.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={4}>
        <ChartComponent data={chartData} />
      </Box>
    </Box>
    
  );
};

export default IncomeOverviewShow;

interface IncomeDetails {
  totalTraffic: number;
  totalRentDays: number;
  averageRentDays: number;
  totalIncome: number;
  totalIncomeFromTenants: number;
  totalIncomeFromLandlord: number;
  averageCostPerDay: number;
  totalContracts: number;
  totalPayOutAmount: number;
  totalRejectedContracts: number;
  averageTimeBetweenBookingAndArrival: number;
  totalPayInCommission: number,
  totalPayOutCommission: number,
}